<template>
  <ActionDialog ref="dialog" max_width="50%">
    <template #title>
      <v-card-title class="text-capitalize">
        Activate All Sites
      </v-card-title>
    </template>

    <template>
      <v-select
        v-model="selected_websites"
        label="Sites"
        outlined
        chips
        multiple
        :items="websites"
        :item-text="'name'"
        :item-value="website => website"
        hide-details=auto
        class="mt-3 rounded-lg"
      ></v-select>
    </template>
  </ActionDialog>
</template>

<script>
import ActionDialog from "@/components/ActionDialog.vue";

export default {
  components: {
    ActionDialog,
  },
  props: {
    websites: Array,
  },
  data() {
    return {
      selected_websites: [],
    };
  },
  watch: {
    websites: {
      immediate: true,
      handler(newWebsites) {
        this.selected_websites = [...newWebsites];
      }
    }
  },
  methods: {
    showDialog() {
      this.$refs.dialog.showDialog()
        .then(() => {
          this.$emit("confirm-selection", this.selected_websites);
        })
        .catch(() => {});
    },
  },
};
</script>