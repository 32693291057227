<template>
  <v-row>
    <!-- Left-hand side column -->
    <v-col cols=3>
      <v-card elevation=4 outlined class="rounded-lg">
        <v-card-title>
          Product
          <v-spacer/>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click="syncProduct({ product_id: product.product_id, product_name: product.name, website_id: website.website_id, website_name: website.name, website_type: website.type })">mdi-autorenew</v-icon>
            </template>
            <span>Sync Product</span>
          </v-tooltip>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-row dense>
            <v-col cols=auto>
              <v-img :src="productImage" gradient="to bottom, rgba(0,0,0,.03), rgba(0,0,0,.05)" height="70px" width="70px" class="image-border" contain/>
            </v-col>
            <v-col>
              <p class="mt-n1 mb-1">Status: <v-chip small :color="product.website_options[website.website_id].external_product_id ? `success` : `error`">{{ product.website_options[website.website_id].external_product_id ? 'Published' : 'Unpublished' }} {{ ('last_published' in product.website_options[website.website_id] && product.website_options[website.website_id].last_published !== null) ? new Date(`${product.website_options[website.website_id].last_published.replaceAll('-', '/')} UTC`).toLocaleString() : '' }}</v-chip></p>
              <p class="mb-1" v-if="product.website_options[website.website_id].external_product_id">External ID: <v-chip small outlined @click="goToWebsiteProduct">{{ product.website_options[website.website_id].external_product_id }}</v-chip></p>
              <p class="mb-0">Last Updated: <v-chip small outlined>{{ product.website_options[website.website_id].last_updated !== null ? new Date(`${product.website_options[website.website_id].last_updated.replaceAll('-', '/')} UTC`).toLocaleString() : 'Never' }}</v-chip></p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Subtabs -->
      <v-card elevation=4 outlined class="rounded-lg mt-3">
        <v-card-title>Subtabs</v-card-title>
        <v-divider/>
        <v-card-text style="padding: 4px">
          <v-list nav>
            <v-list-item-group v-model="subtab_selection" mandatory color="primary">
              <v-list-item v-for="subtab in subtabs" :key="subtab.title" link>
              <v-list-item-icon>
                <v-icon>{{ subtab.icon }}</v-icon>
              </v-list-item-icon>
                <v-list-item-title>{{ subtab.title }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- Right-hand side column -->
    <v-col cols=9>
      <v-form v-model="valid" @input="$emit('update:changes_valid', valid)">
        <!-- Visibility Data Card -->
        <v-expand-transition>
          <v-card elevation=4 outlined class="rounded-lg mb-3" v-show="subtab_selection === 0 || subtab_selection === 5">
            <v-card-title>{{ subtabs[5].title }}</v-card-title>
            <v-divider/>
            <v-card-text>
              <v-select
                v-model="product.website_options[website.website_id].status"
                :items="['Active', 'Archived', 'Draft']"
                label="Select Visibility"
                :rules="[v => v !== null || `Product status is required`]"
                outlined
                hide-details
                :readonly="!access.update_access"
              ></v-select>
            </v-card-text>
          </v-card>
        </v-expand-transition>

        <!-- Tags Card -->
        <v-expand-transition>
          <v-card elevation=4 outlined class="rounded-lg mb-3" v-show="subtab_selection === 0 || subtab_selection === 3">
            <v-card-title>{{ subtabs[3].title }}</v-card-title>
            <v-divider/>
            <v-card-text>
              <v-row justify="center">
                <v-col cols="5.5">
                  <v-card outlined>
                    <v-card-title class="justify-center">
                      <h3>Available Tags</h3>
                      <v-spacer></v-spacer>
                      <v-toolbar style="max-width: 200px;" dense flat>
                        <v-text-field
                          v-model="search"
                          hide-details
                          prepend-icon="mdi-magnify"
                          single-line
                        ></v-text-field>
                      </v-toolbar>
                    </v-card-title>
                    <v-card-text :style="'height:204px;' + showScrollAvailableTags">
                      <draggable class="list-group" v-model="available_tags" group="tags" :style="`padding-bottom: ${AvailableDnDHeight}px;`" :disabled="!access.update_access">
                        <div class="list-group-item" v-for="(tag, i) in available_tags" :key="i" style="text-align: center;" @click="addTag(tag)">
                          <span>
                            <v-icon class="handle">mdi-drag</v-icon>
                            {{ tag }}
                          </span>
                        </div>
                      </draggable>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-icon>mdi-chevron-double-right</v-icon>

                <v-col cols="5.5">
                  <v-card outlined>
                    <v-card-title class="justify-center">
                      <h3>Active Tags</h3>
                      <v-spacer></v-spacer>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="white" class="text--primary" fab small v-on="on" v-bind="attrs" @click="addingTag = !addingTag" :disabled="!access.update_access">
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                          <ShopifyEditSiteTags
                          :show.sync="addingTag"
                          :created_tags="product.website_options[website.website_id].created_tags"
                          ></ShopifyEditSiteTags>
                        </template>
                        <span>Create new Tag</span>
                      </v-tooltip>
                    </v-card-title>
                    <v-card-text :style="'height:204px;' + showScrollActiveTags">
                      <draggable class="list-group" v-model="active_tags" group="tags" :style="`padding-bottom: ${ActiveDnDHeight}px;`" :disabled="!access.update_access">
                        <div class="list-group-item" v-for="(tag, i) in active_tags" :key="i" style="text-align: center;">
                          <span>
                            <v-icon class="handle">mdi-drag</v-icon>
                              {{ tag }}
                            <v-icon class="close" @click="removeTag(tag, i)" v-if="access.update_access">mdi-close</v-icon>
                          </span>
                        </div>
                      </draggable>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-expand-transition>

        <!-- Metafields Card -->
        <v-expand-transition>
          <v-card elevation=4 outlined class="rounded-lg mb-3" v-show="subtab_selection === 0 || subtab_selection === 4">
            <v-card-title>
              {{ subtabs[4].title }}

              <v-spacer/>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon large @click="$refs.metafield_dialog.showDialog()" :disabled="!access.create_access" class="my-n2"><v-icon>mdi-plus</v-icon></v-btn>
                </template>
                <span>Add Metafield</span>
              </v-tooltip>
              <CreateMetafieldDialog
                ref="metafield_dialog"
                :product="product"
                :metafields_data="metafields_data"
                :website="website"
                :access="access"
                @update_mode="metafields_data.mode = $event"
              ></CreateMetafieldDialog>
            </v-card-title>
            <v-divider/>
            <v-card-text>
              <v-window style="overflow: visible;">
                <v-btn-toggle v-model="metafields_data.mode" mandatory style="justify-content: space-evenly;" active-class="current-tab">
                  <v-row>
                    <v-col>
                      <v-btn elevation=4 class="button-style rounded-lg" value="product">Product</v-btn>
                    </v-col>
                    <v-col>
                      <v-btn elevation=4 class="button-style rounded-lg" value="variants">Variants</v-btn>
                    </v-col>
                  </v-row>
                </v-btn-toggle>
              </v-window>

              <v-expand-transition>
                <v-select
                  v-if="metafields_data.mode === 'variants'"
                  v-model="metafields_data.variant"
                  :items="product.variants"
                  label="Select Variant"
                  :item-text="product.variants[0].variant_name ? 'variant_name' : 'sku'"
                  item-value="variant_id"
                  outlined
                  hide-details=auto
                  :readonly="!access.update_access"
                  class="mt-4 rounded-lg"
                ></v-select>
              </v-expand-transition>
            </v-card-text>

            <v-divider/>

            <v-card-text>
              <v-card
                v-for="(metafield, i) in metafields"
                outlined
                class="rounded-lg"
                :class="{ 'mb-3': i < metafields.length - 1 }"
                :key="i"
              >
                <v-card-text class="pr-2">
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        :value="getMetafieldNamespaceKey(metafield)"
                        label="Namespace and Key"
                        outlined
                        :rules="[
                          v => !!v || 'Required field',
                          v => !!v.split('.')[0] || 'Namesapce is required',
                          v => !!v.split('.')[1] || 'Key is required'
                        ]"
                        hide-details=auto
                        class="rounded-lg"
                        @input="updateMetafieldNamespaceKey($event, metafield)"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <MetafieldValue
                        :metafield="metafield"
                      ></MetafieldValue>
                    </v-col>
                    <v-col cols=auto class="my-auto pa-0">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" icon @click="removeMetafield(metafield, i)"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
                        </template>
                        <span>Remove Metafield</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <v-card v-if="metafields.length === 0" outlined class="rounded-lg" @click="$refs.metafield_dialog.showDialog()">
                <v-card-title class="justify-center primary--text">
                  <v-icon color="primary" class="pr-1">mdi-plus</v-icon>
                  Add Metafield
                </v-card-title>
              </v-card>
            </v-card-text>
          </v-card>
        </v-expand-transition>

        <!-- Localized Information Card -->
        <v-expand-transition>
          <v-card elevation=4 outlined class="rounded-lg mb-3" v-show="subtab_selection === 0 || subtab_selection === 1">
            <v-card-title>{{ subtabs[1].title }}</v-card-title>
            <v-card-subtitle>
                This content is independent of the main product content and will be used soley for the <b>{{ website.name }}</b> site.
            </v-card-subtitle>
            <v-switch
              v-model="product.website_options[website.website_id].use_custom_fields"
              label="Use Localized Information"
              inset
              style="position: absolute; right: 0; top: 0; padding-right: 30px;"
              @change="populateLocalizedInformation"
            ></v-switch>
            <v-divider/>

            <v-card-text>
              <v-text-field v-model="product.website_options[website.website_id].name" :rules="product.website_options[website.website_id].use_custom_fields ? [ v => !!v || `Product name is required`] : []" :label="`${website.type} Name`" :readonly="!access.update_access" :disabled="!product.website_options[website.website_id].use_custom_fields" outlined hide-details></v-text-field>
            </v-card-text>

            <v-divider/>

            <v-card-text>
              <h4>{{ website.type}} Description</h4>
              <CKEditorWrapper v-model="product.website_options[website.website_id].description" :access="access" :disabled="!product.website_options[website.website_id].use_custom_fields"></CKEditorWrapper>
            </v-card-text>
          </v-card>
        </v-expand-transition>

        <!-- SEO Information Card -->
        <v-expand-transition>
          <v-card elevation=4 outlined class="rounded-lg mb-3" v-show="subtab_selection === 0 || subtab_selection === 2">
            <v-card-title>
              {{ subtabs[2].title }}
              <v-spacer />
              <v-btn text small color="primary" @click="populateMetaFields">Populate Fields</v-btn>
            </v-card-title>
            <v-divider/>
            <v-card-text>
              <v-text-field
                v-model="product.website_options[website.website_id].meta_title"
                label="SEO Title"
                :readonly="!access.update_access"
                outlined
                class="rounded-lg"
                maxlength="70"
                counter
              >
                <template v-slot:counter="{ props }">
                  <div class="v-counter theme--light">Used {{ props.value }} of {{ props.max }} available</div>
                </template>
              </v-text-field>
              <v-textarea
                v-model="product.website_options[website.website_id].meta_description"
                label="SEO Description"
                :readonly="!access.update_access"
                counter
                maxlength="320"
                outlined
                class="rounded-lg"
              >
                <template v-slot:counter="{ props }">
                  <div class="v-counter theme--light">Used {{ props.value }} of {{ props.max }} available</div>
                </template>
              </v-textarea>
              <v-text-field
                v-model="product.website_options[website.website_id].url_handle"
                label="Handle"
                :readonly="!access.update_access"
                outlined
                hide-details
                :prefix="handlePrefix"
                class="rounded-lg handle-prefix"
              ></v-text-field>
            </v-card-text>
          </v-card>
        </v-expand-transition>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import CKEditorWrapper from '@/components/CKEditorWrapper.vue'
import draggable from "vuedraggable";
import ShopifyEditSiteTags from "@/components/product/website_options/shopify/ShopifyEditSiteTags.vue"
import CreateMetafieldDialog from "@/components/product/website_options/shopify/CreateMetafieldDialog.vue"
import MetafieldValue from "@/components/product/website_options/shopify/MetafieldValue.vue"
export default {
  data() {
    return {
      subtab_selection: 0,
      subtabs: [
        { title: 'All Options', icon: 'mdi-all-inclusive'} ,
        { title: 'Localized Information', icon: 'mdi-earth' },
        { title: 'SEO Information', icon: 'mdi-text-search-variant' },
        { title: 'Tags', icon: 'mdi-tag' },
        { title: 'Metafields', icon: 'mdi-database-cog' },
        { title: 'Visibility', icon: 'mdi-eye' },
      ],
      search: "",
      addingTag: false,
      valid: true,
      metafields_data: {
        mode: "product",
        variant: null,
      },
    }
  },
  props: {
    product: Object,
    brands: Array,
    website: Object,
    access: Object,
    syncProduct: Function,
  },
  components: {
    draggable,
    ShopifyEditSiteTags,
    CKEditorWrapper,
    CreateMetafieldDialog,
    MetafieldValue,
  },
  methods: {
    goToWebsiteProduct() {
      window.open(`${this.website.url.replace('api','products/')}${this.product.website_options[this.website.website_id].external_product_id}`,'_newtab');
    },
    populateLocalizedInformation(use_localized) {
      if (use_localized) {
        // Populate with name
        if (this.product.website_options[this.website.website_id].name === '') {
          this.product.website_options[this.website.website_id].name = this.product.name;
        }
        // Populate with description
        if (this.product.website_options[this.website.website_id].description === '') {
          this.product.website_options[this.website.website_id].description = this.product.description;
        }
      }
    },
    populateMetaFields() {
      // Generate the meta title
      // Get the brand name by reverse mapping the product's brand ID
      let brand_name = this.brands[this.brands.findIndex(x => x.brand_id === this.product.brand_id)].name.toUpperCase();
      // Set the meta title to the product name
      let meta_title = this.product.name;
      // If the first word in the title is the brand name
      if (meta_title.split(' ')[0].toUpperCase() === brand_name) {
        // Remove the brand name from the meta_title
        meta_title = this.product.name.slice(this.product.name.split(' ')[0].length +1)
      }

      // Generate the URL handle
      let handle = meta_title.toLowerCase().replaceAll(' ', '-');

      // If a meta title format is provided/setup in the website settings
      if (this.website.settings.meta_title_format) {
        // Add the prefix and suffix
        meta_title = this.website.settings.meta_title_format.replace("{title}", meta_title);
      }

      // Generate the meta description
      let meta_description = this.product.description;
      // Remove <br> elements from the product description
      if (meta_description) {
        meta_description = meta_description.replaceAll(/<br>/g, '');
        // Remove <script> and <iframe> elements as well as any empty elements
        meta_description = meta_description.replaceAll(/<iframe.*<\/iframe>|<script[^]*<\/script>/g, '');
        // Split on any valud HTML element and get the first one
        meta_description = meta_description.split(/<[^>]*>/g)[1];
        // Remove &nbsp; chars
        meta_description = meta_description.replace(/<.*>&nbsp;<\/.*>/g, '');("&nbsp;", "");
      }
      // Set the title, description, and handle to the generated values
      this.product.website_options[this.website.website_id].meta_title = meta_title;
      this.product.website_options[this.website.website_id].meta_description = meta_description;
      this.product.website_options[this.website.website_id].url_handle = handle;
    },
    addTag(tag) {
      // Adds a tag from Available tags to Active tags
      this.product.website_options[this.website.website_id].tags.push(tag);
    },
    removeTag(tag, index) {
      // Moves a tag to Available tags or deletes it
      // If tag has been created, delete it
      if (this.product.website_options[this.website.website_id].created_tags.includes(tag)) {
        this.product.website_options[this.website.website_id].created_tags.splice(this.product.website_options[this.website.website_id].created_tags.findIndex(x => x === tag), 1);
      // Otherwise, move it back to Available Tags
      } else {
        this.website.options.push(tag);
        this.product.website_options[this.website.website_id].tags.splice(index, 1);
      }
    },
    getMetafieldNamespaceKey(metafield) {
      return `${metafield.namespace}.${metafield.key}`;
    },
    updateMetafieldNamespaceKey(namespace_key, metafield) {
      let [ namespace, key ] = namespace_key.split('.');
      metafield.namespace = namespace;
      metafield.key = key ? key : '';
    },
    removeMetafield(metafield, index) {
      // If the metafield exists and is not already deleted
      if ('metafield_id' in metafield && !('is_deleted' in metafield)) {
        this.$set(metafield, 'is_deleted', true);
      } else {
        // Switch on metafields mode to know which array to splice from
        switch (this.metafields_data.mode) {
          case 'product':
            this.product.website_options[this.website.website_id].metafields.splice(index, 1);
            break;
          case 'variants':
            var variant = this.product.website_options[this.website.website_id].variants.find(x => x.variant_id === this.metafields_data.variant);
            variant.metafields.splice(index, 1);
            break;
        }
      }
    },
    initializeNewWebsiteVariant() {
      // Initialize the a blank variant DTO
      let variant = {
        external_variant_id: null,
        metafields: [],
        product_id: this.product.product_id,
        variant_id: this.metafields_data.variant,
        website_id: this.website.website_id
      }

      // Push the new variant to the website variants array
      this.product.website_options[this.website.website_id].variants.push(variant);
      this.$store.state.products.oldData.product.website_options[this.website.website_id].variants.push(JSON.parse(JSON.stringify(variant)));

      // Return the blank variant DTO
      return variant;
    }
  },
  watch: {
    'metafields_data.mode'(mode) {
      if (mode === 'variants' && this.metafields_data.variant === null) {
        this.metafields_data.variant = this.product.variants[0].variant_id;
      }
    }
  },
  computed: {
    metafields() {
      let metafields;
      if (this.metafields_data.mode === 'product') {
        metafields = this.product.website_options[this.website.website_id].metafields;
      }
      else if (this.metafields_data.mode === 'variants') {
        let variant = this.product.website_options[this.website.website_id].variants.find(x => x.variant_id === this.metafields_data.variant);
        // If the variant is not in the website options, create a new one
        if (variant === undefined) {
          variant = this.initializeNewWebsiteVariant();
        }
        metafields = variant.metafields;
      }
      // Filter out the deleted metafields
      metafields = metafields.filter(x => x.is_deleted === undefined);
      return metafields;
    },
    handlePrefix() {
      let url = this.website.url;
      if (url.endsWith('/admin/api')) {
        url = url.replace('/admin/api', '/products/');
      } else {
        url += '/products/';
      }
      return url;
    },
    productImage() {
      if (this.product.images.length > 0) {
        return this.product.images[0].image_url;
      }
      return process.env.VUE_APP_NO_IMAGE_IMG;
    },
    active_tags: {
      get: function() {
        let temp_set = new Set();
        for (var tag in this.product.website_options[this.website.website_id].tags) {
          temp_set.add(this.product.website_options[this.website.website_id].tags[tag]);
        }
        for (var ctag in this.product.website_options[this.website.website_id].created_tags) {
          temp_set.add(this.product.website_options[this.website.website_id].created_tags[ctag]);
        }
        return Array.from(temp_set);
      },
      set: function(tags) {
        this.product.website_options[this.website.website_id].tags = tags;
      }
    },
    available_tags: {
      get: function() {
        // Create a new temporary Set
        let temp_set = new Set();

        // Add each of the website tags to the temporary Set
        for (var i in this.website.options) {
          temp_set.add(this.website.options[i]);
        }

        // Remove tags from the website tags if already on product
        for (var j in this.product.website_options[this.website.website_id].tags) {
          if (temp_set.has(this.product.website_options[this.website.website_id].tags[j])) {
            temp_set.delete(this.product.website_options[this.website.website_id].tags[j])
          }
        }
        // Convert set to array and then filter based on search
        return Array.from(temp_set).filter(tag => {
            if (tag !== "") {
              return tag.toLowerCase().includes(this.search.toLowerCase());
            }
        });
      },
      set: function(tags) {
        // Iterate over tags to check if they are created
        for (var i in tags) {
          // If moving a created tags, delete it
          if (this.product.website_options[this.website.website_id].created_tags.includes(tags[i])) {
            this.product.website_options[this.website.website_id].created_tags.splice(this.product.website_options[this.website.website_id].created_tags.findIndex(x => x === tags[i]), 1);
          }
        }
      }
    },
    showScrollAvailableTags() {
      return this.available_tags.length > 4 ? 'overflow-y: scroll;' : '';
    },
    showScrollActiveTags() {
      return this.active_tags.length > 4 ? 'overflow-y: scroll;' : '';
    },
    AvailableDnDHeight() {
      switch (this.available_tags.length) {
        case 0: return 188
        case 1: return 141
        case 2: return 94
        case 3: return 47
        default: return 0
      }
    },
    ActiveDnDHeight() {
      switch (this.active_tags.length) {
        case 0: return 188
        case 1: return 141
        case 2: return 94
        case 3: return 47
        default: return 0
      }
    }
  }
}
</script>

<style scoped>
.list-group-item {
    position: relative;
    display: block;
    padding: .75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
    cursor: move;
}
.list-group {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}
.handle {
  float: left;
}
.close {
  float: right;
}
.handle-prefix {
  color: grey;
}
</style>